
import { illustration5 } from "../Component/imageassets";
import { App } from "./../App";
import BreadcrumbComponent from "./../Component/breadcrumbComponent";
import { Formvouchersearch } from './../Component/FormVoucherSearchComponent';


function ConsulterRecuPage(Props) {
  return (

    <>
    <BreadcrumbComponent titre={"Recherche De Coupon"} />
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6 my-auto">
          <img className="img-fluid w-55" src={illustration5} alt="" />
        </div>
        <div className="col-md-6 my-auto">
          <Formvouchersearch />
        </div>
      </div>
    </div>
  </>
  );
}
const ConsulterRecu = () => <App component={ConsulterRecuPage} />;

export default ConsulterRecu;
