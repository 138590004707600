import React from "react";
import { ErrorMessage, useField } from "formik";

export const TextField = ({ label, ...props }) => {
  const [field, meta,] = useField(props);
  return (
    <div className="mb-2">
      <label htmlFor={field.name}>{label}</label>
      <input
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

export const SelectOption = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <label htmlFor={field.name}>{label}</label>
      <select name={field.name}>

        <option value="Acte naissance" label="Acte naissance" className= {`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid" && "form-select"
        }`}
        {...field}
        {...props}>
        Acte naissance
        </option>
        <option value="Casier Judiciaire" label="Casier judiciaire" className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}>
        Casier Judiciaire
        </option>
      </select>
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

export const TextArea = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return(
    <div className="mb-2">
      <label htmlFor={field.name}>{label}</label>
      <textarea
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
    </div>
  );
}
