import React from "react";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";

export const Formvouchersearch = () => {
  const validate = Yup.object({
    user_payment_transaction_id: Yup.string()
      .min(8, "Doit comporter 10 caractères")
      .required("Ce champ est obligatoire"),
  });

  return (
    <Formik
      initialValues={{
        user_payment_transaction_id: "",
      }}
      validationSchema={validate}
      onSubmit={(values) => {

      }}
    >
      {(formik) => (
        <div>
          <h1 className="my-4 font-weight-bold-display-4">
            Recherchez votre coupon
          </h1>
          <h6 className="my-1 font-weight-normal-display-2">Entrez la reférence de la transaction</h6>
          {console.log(formik)}
          <Form>
            <TextField name="user_payment_transaction_id" type="text" />
            <div className="text-center">
            <button className="btn btn-danger mr-2"  type="reset">
                Effacer
              </button>
              <button type="submit" className="btn btn-edar  ml-2">
                Continuer
              </button>
              <div className="mt-2"></div>
            </div>

          </Form>
        </div>
      )}
    </Formik>
  );
};
