import React from "react";
import { CardList } from "./CardComponent";

export default class FeatureComponent extends React.Component {
  render = () => {
    return (
      <section className="w3l-features py-5" id="facilities">
        <div className="call-w3 py-lg-5 py-md-4 py-2">
          <div className="container">
            <div className="row main-cont-wthree-2">
              <div className="col-lg-5 feature-grid-left">
                <h3 className="title-big mb-4">Authenticité </h3>
                <p className="text-para">
                  Si vous faites des affaires ou voyagez à l’étranger, il se
                  peut que vous ayez besoin de prouver l’authenticité de
                  documents officiels délivrés en Côte d'Ivoire. Les consulats
                  et les ambassades demandent des certificats d’authenticité
                  pour s’assurer que ces divers documents sont valides. BVS
                  authentifie des documents en vérifiant l'existence, le sceau,
                  le tampon et la signature
                </p>
                <p className="mt-3">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Voluptas ab qui impedit, libero illo quia sequi quibusdam
                  iure. Error minus quod reprehenderit quae dolor velit soluta
                  animi voluptate dicta enim? Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Odio, provident!
                </p>

              </div>
              <CardList></CardList>
            </div>
          </div>
        </div>
      </section>
    );
  };
}
