import React from "react";
import { Formik, Form } from "formik";
import { TextField,TextArea } from "./TextField";
import * as Yup from "yup";

export const ContactFormComponent = () => {
  const validate = Yup.object({
    user_first_name: Yup.string()
      .min(2, "Doit comporter au moins 2 caractères")
      .required("Ce champ est obligatoire"),
    user_email: Yup.string()
      .email("L'adresse électronique n'est pas valide")
      .required("L'adresse électronique est obligatoire"),
    user_object: Yup.string().required("Ce champ est obligatoire"),
    user_message: Yup.string().required("Ce champ est obligatoire"),
  });

  return (
    <Formik
      initialValues={{
        user_first_name: "",
        user_email: "",
        user_object: "",
        user_message: "",
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {(formik) => (
         <div className="map-content-9">
          <h5 className="mb-sm-4 mb-3">Laissez-nous un message</h5>
          <Form>
            <div className="twice-two">
              <TextField
                name="user_first_name"
                type="text"
                id="w3lName"
                placeholder="Nom & prénoms"
              />
              <TextField name="user_email" type="email" placeholder="E-mail" />
            </div>

            <div className="twice">
              <TextField
                name="user_object"
                type="text"
                id="w3lSubject"
                placeholder="Objet"
              />
              <TextArea
                name="user_message"
                type="text"
                id="w3lMessage"
                placeholder="Votre Message"
              />
            </div>

            <div className="text-right">

              <button type="submit" className="btn btn-edar  ml-2">
                J'envoie mon message
              </button>
              <div className="mt-5"></div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

