import React from "react";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";

export const Formachatcoupon = () => {
  const validate = Yup.object({
    /*  user_document_choice: Yup.string()
    .min(1,"Choisissez un type de document")
    .required("Choisissez la nature du document svp!"), */
    user_last_name: Yup.string()
      .min(2, "Doit comporter au moins 2 caractères")
      .required("Ce champ est obligatoire"),
    user_first_name: Yup.string()
      .min(2, "Doit comporter au moins 2 caractères")
      .required("Ce champ obligatoire"),
    user_email: Yup.string()
      .email("L'adresse électronique n'est pas valide")
      .required("L'adresse électronique est obligatoire"),
    user_phone_number_prefix: Yup.string()
      .max(5, "L'indicatif doit comporter au plus 5 caractères")
      .required("L'indicatif est obligatoire"),
    user_phone_number: Yup.string()
      .min(8, "Le numéro doit comporter au moins 8 chiffres")
      .max(10, "Le numéro doit comporter au plus 10 chiffres")
      .required("Le numéro de téléphone est obligatoire"),
  });

  return (
    <Formik
      initialValues={{
        user_document_choice: "",
        user_last_name: "",
        user_first_name: "",
        user_email: "",
        user_phone_number_prefix: "+225",
        user_phone_number: "",
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {(formik) => (
        <div className="map-content-9">
          <h5 className="mb-sm-4 mb-3">Acheter votre coupon</h5>

          <Form>

            <div className="twice">
              {/* <SelectOption label="Nature du document" name="user_document_choice" /> */}
              <TextField label="Nom" name="user_last_name" type="text" />
              <TextField label="Prénom(s)" name="user_first_name" type="text" />
              <TextField label="E-mail" name="user_email" type="email" />
            </div>
            <div className="twice-two">
              <TextField
                label="Indicatif"
                name="user_phone_number_prefix"
                type="text"
              />
              <TextField
                label="Téléphone"
                name="user_phone_number"
                type="text"
              />
            </div>

            <div className="text-right">
              <button className="btn btn-danger mr-2" type="reset">
                Effacer
              </button>
              <button type="submit" className="btn btn-edar  ml-2">
                Continuer
              </button>
              <div className="mt-5"></div>
            </div>

          </Form>
        </div>
      )}
    </Formik>
  );
};
