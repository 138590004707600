import React from "react";
import { brand1, brand2, brand3, brand4, authentification1 } from "./imageassets";

let partnerList = [
  { name: brand1 },
  { name: brand2 },
  { name: brand3 },
  { name: brand4 },
  { name: authentification1 },
];

export class PartenerList extends React.Component {
  render = () => {
    return (
      <>
        {partnerList.map((item) => {
          return <PartnerLogo key={item.name} name={item.name}/>;
        })}
      </>
    );
  };
}

function PartnerLogo(props) {
  let name = props.name;
  return (
    <div className="col-lg-2 col-md-3 col-4">
      <img src={name} alt="" className="img-fluid" />
    </div>
  );
}
