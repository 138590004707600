import { illustration6 } from "../Component/imageassets";
import { App } from "./../App";
import BreadcrumbComponent from "./../Component/breadcrumbComponent";
import { Formvreclamation } from './../Component/FormReclamationComponent';

function ReclamationPage(Props) {
  return (
    <>
    <BreadcrumbComponent titre={"Réclamation"} />
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6 my-auto mb-4">
          <img className="img-fluid w-50" src={illustration6} alt="" />
        </div>
        <div className="col-md-6 my-auto">
          <Formvreclamation />
        </div>
      </div>
    </div>
  </>
  );
}
const Reclamation = () => <App component={ReclamationPage} />;

export default Reclamation;
