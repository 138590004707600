import React from "react";
import { illustration1 } from "../Component/imageassets";
import { App } from "./../App";
import BreadcrumbComponent from "./../Component/breadcrumbComponent";
import { Formachatcoupon } from "./../Component/FormAchatComponent";

function AchatCouponPage(Props) {
  return (
    <>
      <BreadcrumbComponent titre={"Achat du Coupon"} />
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6 my-auto">
            <img className="img-fluid w-60" src={illustration1} alt="" />
          </div>
          <div className="col-md-5">
            <Formachatcoupon />
          </div>
        </div>
      </div>
    </>
  );
}
const Achatcoupon = () => <App component={AchatCouponPage} />;

export default Achatcoupon;
