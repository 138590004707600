
import "./App.css";
import "./style.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Component/imageassets";
import HeaderComponent from "./Component/HeaderComponent";
import FooterComponent from './Component/FooterComponent';
import { BrowserRouter } from "react-router-dom";
import RouterComponent from './Route/RouterComponent';


export function App(props) {
let Component = props.component??<></>;
  return (
    <div className="App">
     <HeaderComponent></HeaderComponent>
     <Component></Component>
     <FooterComponent></FooterComponent>
    </div>
  );
}

const Home = ()=> (<BrowserRouter><RouterComponent/></BrowserRouter>);
export default Home;
