import React from "react";

let cardList = [
  {
    id: 1,
    icon: "file",
    titre: "Document Administratif",
    description:
      "Vivamus a Vivamus a ligula quam. Ut blandit eu leo non. Duis sed doloramet ligula quam. Ut blandit eu leo non.",
  },
  {
    id: 2,
    icon: "graduation-cap",
    titre: "Diplôme",
    description:
      "Duis sed dolor amet laoreet Vivamus a ligula quam. Ut blandit eu leo non. Duis sed doloramet",
  },
  {
    id: 3,
    icon: "id-card",
    titre: "Pièce d'Identité",
    description:
      "Duis sed dolor amet laoreet Vivamus a ligula quam. Ut blandit eu leo non. Duis sed doloramet",
  },
  {
    id: 4,
    icon: "address-card",
    titre: "Documents Judiciaires",
    description:
      "Une vérification du casier judiciaire à l’échelle nationale pour vous fournir autant d’informations que possible sur les antécédents criminels d’une personne",
  },
];

export class CardList extends React.Component {
  render = () => {
    return (
      <div className="col-lg-7 feature-grid-right mt-lg-0 mt-5">
        <div className="call-grids-w3 d-grid">
            {cardList.map((item)=>{
                return(<CardElement key={item.id} icon={item.icon} titre={item.titre} description={item.description}></CardElement>)
            })}
        </div>
      </div>
    );
  };
}

function CardElement(props) {
    let icon = props.icon;
    let titre = props.titre;
    let description = props.description

  return (
    <div className="grids-1 box-wrap">
      <span className="icon">
        <span className={"fa fa-"+icon}></span>
      </span>
      <h4>
        <p>{titre}</p>
      </h4>
      <p>
       {description}
      </p>
    </div>
  );
}
