import React from "react";
import BoutonContactComponent from './BoutonContactComponent';
import BoutonCallActionComponent from './BoutonCallActionComponent';

export default class MiddleComponent extends React.Component{
    render=()=>{
        return(
            <div className="middle py-5">
        <div className="container py-lg-5 py-md-4 py-2">
            <div className="welcome-left text-center py-lg-4">
                <h3 className="title-big"> Authentifiez vos documents en ligne</h3>
                <h5 className="title-small mb-1">SIMPLE, RAPIDE ET FIABLE</h5>
                <BoutonContactComponent></BoutonContactComponent>
                <BoutonCallActionComponent></BoutonCallActionComponent>
            </div>
        </div>
    </div>
        )

    }
}